<!--<div class="container-fluid">
  <div class="jumbotron">
    <ul class="list-group text-center mx-auto" #modules ngDefaultControl>
      <li
        class="list-group-item list-group-item-action"
        *ngFor="let module of allModules"
      >
        <input
          type="checkbox"
          id="{{ 'module-' + module.id }}"
          [(ngModel)]="module.active"
          (click)="onSelectModule(module)"
        />
        <label class="list-group-item" for="{{ 'module-' + module.id }}">{{module.description}}</label>
      </li>
    </ul>
    <div class="form-group form-footer text-center">
    </div>
  </div>
</div>-->


<div class="container-fluid">
  <div class="jumbotron">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Moduli</a>
        <ng-template ngbNavContent>
          <ul class="list-group text-center mx-auto" #modules ngDefaultControl>
            <li
              class="list-group-item list-group-item-action"
              *ngFor="let module of allModules"
            >
              <input
                type="checkbox"
                id="{{ 'module-' + module.id }}"
                [(ngModel)]="module.active"
                (click)="onSelectModule(module)"
              />
              <label class="list-group-item" for="{{ 'module-' + module.id }}">{{module.description}}</label>
            </li>
          </ul>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Config</a>
        <ng-template ngbNavContent>
          <div class="mb-3">
            <label id="labelActivationKey" for="editActivationKey" class="form-label">Chiave attivazione</label>
            <input class="form-control" id="editActivationKey" [(ngModel)]="config.activationKey" disabled="true">
            <button class="btnUpdate btn-primary btn-sm" for="labelActivationKey" (click)="onGenerate()">
              Genera
            </button>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" id="checkSession1" [(ngModel)]="config.flagTypeSession" [value]="0">
            <label class="form-check-label" for="checkSession1">
              Sessione a utente
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" id="checkSession2" [(ngModel)]="config.flagTypeSession" [value]="1">
            <label class="form-check-label" for="checkSession2">
              Sessione a dispositivo
            </label>
          </div>
          <br>
          <div class="mb-3">
            <label for="editConcurrence" class="form-label">Contemporaneità</label>
            <input class="form-control" id="editConcurrence" [(ngModel)]="config.concurrence">
          </div>
          <div class="mb-3">
            <label for="editUriBackend" class="form-label">URI Backend</label>
            <input class="form-control" id="editUriBackend" [(ngModel)]="config.uriBackend">
          </div>
          <button class="btnUpdate btn-primary btn-sm" (click)="onSave()">
            Salva
          </button>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
