import { Customer } from '../classes/Customer';
import { ConfigService } from '../services/config.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faPen, faTrash, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'tr[app-user]',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
})
export class CustomerComponent implements OnInit {
  @Input('customer-data') customer: Customer; //quello fra parentesi è l'alias globale
  @Output() onSelectCustomer = new EventEmitter<Customer>();
  @Output('onDeleteUser') customerDeleted = new EventEmitter<Customer>();

  constructor(private configService: ConfigService, private route: Router) {}

  ngOnInit() {}

  updateCustomer() {
    this.route.navigate(['customers', this.customer.id, 'edit']);
    this.onSelectCustomer.emit(this.customer);
  }
  enableCustomer(e: Event) {
    e.preventDefault();
    this.customerDeleted.emit(this.customer);
  }

  modulesCustomer() {
    this.route.navigate(['customers', this.customer.id, 'modules']);
    this.onSelectCustomer.emit(this.customer);
  }

  applicationsCustomer() {
    this.route.navigate(['customers', this.customer.id, 'applications']);
    this.onSelectCustomer.emit(this.customer);
  }
}
