import { ConfigService } from './../services/config.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Application } from '../classes/Application'

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {

  applications: Application[] = [];

  constructor(public configService: ConfigService,
              private router: Router,
              private route: ActivatedRoute) { 
    }


  ngOnInit() {
    this.getAllApplications();
  }

  onNewApplication() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

  getAllApplications() {
    this.configService.getApplications();
    
    this.configService.getAllApplicationsAPI().subscribe(
      (response) => {
        this.applications = response['data']
      }
    )
    }
}
