<app-nav></app-nav>

<button
  class="btn btn-primary"
  (click)="onNewApplication()">Nuova App
</button>
<p></p>
<div class="row">
  <div class="col-2">
    <div class="list-group">
      <a
        [routerLink]="['/applications', application.id, 'edit']"
        routerLinkActive="active"
        href="#"
        class="list-group-item"
        *ngFor="let application of configService.applications">
        {{ application.name }}
      </a>
    </div>
  </div>
  <div class="col-8">
    <router-outlet></router-outlet>
  </div>
</div>

  