<br>
<h1 class="font-weight-bold black-text mb-4 pb-2 text-center">Moduli clienti</h1>
<form #f ="ngForm">
  <!--<img class="mb-4" src="/docs/4.4/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72">-->
 <div class="jumbotron mx-auto">
  <h1 class="h3 mb-3 font-weight-normal text-center">Login</h1>
  <br>
  <div class="input-group mx-auto">
    <label for="inputUsername" class="sr-only">Nome utente</label>
    <input required minlength="1"  [(ngModel)]="username" type="text" name="username" id="inputUsername" class="form-control mb-2" placeholder="Nome utente" required autofocus>
      <!--<div *ngIf="inputUsername.dirty && inputUsername.touched && inputUsername.invalid">
        <div class="alert-danger" *ngIf="inputUsername.errors.required">Compilare il campo utente.</div>
        <div class="alert-danger" *ngIf="inputUsername.errors.minlength">Lunghezza minima {{inputUsername.errors.minlength.requiredLength}} caratteri.</div>
      </div>-->
  </div>
  <div class="input-group mx-auto">
    <label for="inputPassword" class="sr-only">Password</label>
    <input [(ngModel)]="password" type="password" name="password" id="inputPassword" class="form-control mb-2" placeholder="Password" required>
  </div>
  <button class="btn btn-lg btn-primary btn-block mx-auto" type="submit" (click)="loginUser()">Accedi</button>
</div>
</form>
