<div class="form-group">
    <label for="name">Nome Applicazione</label>
    <input
        type="text"
        id="name"
        class="form-control"
        [(ngModel)]="name">
</div>
<div class="form-group">
    <label for="decription">Descrizione Applicazione</label>
    <input
        id="description"
        class="form-control"
        [(ngModel)]="description">
</div>
<button
    class="btn btn-primary"
    (click)="onSaveApplication()">Salva
</button>

