import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  PipeTransform,
} from '@angular/core';
import { ConfigService } from '../services/config.service';
import { Customer } from '../classes/Customer';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
})
export class CustomersComponent implements OnInit {
  p: number = 1;
  pageSize = 5;

  searchText: string;
  customers: Customer[] = [];
  customer: Customer;
  public flagInattivi = true;

  @Output() updateCustomer = new EventEmitter<Customer>();

  constructor(public configService: ConfigService,
              private router: Router) {}

  ngOnInit() {
    this.getCustomers();
  }

  isActive(element, index, array) {
    return element.active;
  }
  getCustomers() {
    this.configService.getAllCustomers().subscribe((response) => {
      this.customers = response['data'];

      this.configService.customers = this.customers;

      if (this.flagInattivi) {
        this.customers = this.customers.filter(this.isActive);
        //console.log(this.customers)
      }
    });
  }

  onSetActiveCustomer(customer: Customer) {
    if (customer.active) {
      if (
        confirm('Sicuro di disabilitare il cliente ' + customer.ragSoc + ' ?')
      ) {
        customer.active = false;
        this.configService.setActiveCustomer(customer).subscribe((response) => {
          this.configService.getAllCustomers().subscribe((response) => {
            this.customers = response['data'];
            this.configService.customers = this.customers;
          });

          //this.clients = this.configService.getClients()
        });
      }
    } else {
      if (
        confirm('Sicuro di riabilitare il cliente ' + customer.ragSoc + ' ?')
      ) {
        customer.active = true;
        this.configService.setActiveCustomer(customer).subscribe((response) => {
          this.configService.getAllCustomers().subscribe((response) => {
            this.customers = response['data'];
            this.configService.customers = this.customers;
          });
        });
      }
    }
  }

  onSelectUser(customer: Customer) {
    const customerCopy = Object.assign({}, customer);
    this.updateCustomer.emit(customerCopy);
  }

  onNewCustomer(){
    this.router.navigate(['customers/new']);
  }

  filterByActive(showInactive: boolean) {
    console.log(showInactive);
  }
}
