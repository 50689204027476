import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthService {

  private APIURL= 'http://licenze.net01cloud.it/auth';

  private username: string;
  private token: string;
  private isUserLogged: boolean = false;

  @Output() userloggedin = new EventEmitter();

  constructor(private http: HttpClient) {
  }

  public isAuthenticated(): boolean {
    if (this.getToken())
     this.isUserLogged = true;
    else
      this.isUserLogged = false;

    return this.isUserLogged;
  }

  public getUsername(): string {
    this.username = localStorage.getItem('username');
    return this.username;
  }

  public getToken(): string {
    this.token = localStorage.getItem('token');
    return this.token;
  }

  /*getAuth() {
    this.token = localStorage.getItem('token');
    if (!this.token) {
      return;
    }
    const httpOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    };
    this.http.get(this.APIURL + '/me', httpOptions).subscribe((response) => {
      if (response['username']) {
        return true;
      } else {
        return false;
      }
    });
  }*/

  public postLogin(username: String, password: String) {
    return this.http
      .post(this.APIURL + '/login', {
        username: username,
        password: password,
      })
      .pipe(
        tap((response) => {
          //console.log(response['token'])
          localStorage.setItem('token', response['token']);
          localStorage.setItem('username', response['id']);

          this.isUserLogged = true;
          this.userloggedin.emit();
        })
      );
    /*
    ,(httpResp)=>{
      const error=httpResp['error'];
      alert(error['message']+' - '+JSON.stringify(error['error'][0].msg))
    }*/
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    this.isUserLogged = false;
  }



}
