<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
  <a class="navbar-brand" href="#">Moduli clienti</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    (click)="isCollapsed = !isCollapsed"
    data-target="#navbarsExample06"
    aria-controls="navbarsExample06"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    class="collapse navbar-collapse"
    id="navBarTop"
    [ngbCollapse]="isCollapsed"
  >
    <!--<ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="#">Giacenze <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Produzione</a>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
      </li>
    </ul>-->

    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a
          class="nav-link"
          href="#"
          routerLink="/customers/"
          (click)="goToCustomers()"
        >
          Clienti
        </a>
      </li>
      <li class="nav-item active">
        <!--
        <a
          class="nav-link"
          href="#"
          routerLink="/customers/new"
          (click)="newCustomer()"
        >
          Nuovo Cliente
        </a>
        -->
      </li>
      <li class="nav-item active">
        <a
          class="nav-link"
          href="#"
          routerLink="/applications/"
          (click)="goToApplications()"
        >
          Applicazioni e moduli
        </a>
      </li>
    </ul>
    <ul class="navbar-nav my-2">
      <li class="nav-item active">
        <a class="nav-link" href="#" routerLink="" (click)="logout()">
          Logout
        </a>
      </li>
      <li class="nav-item disabled">
        <span class="navbar-text"> Utente: {{ username }} </span>
      </li>
    </ul>
  </div>
</nav>
