import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {Module} from '../../classes/Module';
import {Customer} from '../../classes/Customer';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {CustomerModules} from './../../classes/CustomerModules';
import {Application} from "@src/app/classes/Application";
import {CustomerApplication} from "@src/app/classes/CustomerApplication";
import {CustomerApplicationConfig} from "@src/app/classes/CustomerApplicationConfig";

@Component({
  selector: 'app-customer-modules',
  templateUrl: './customer-modules.component.html',
  styleUrls: ['./customer-modules.component.css'],
})
export class CustomerModulesComponent implements OnInit {
  //DICHIARAZIONI
  initialModules: Module[] = [];
  activeModules: Module[] = [];
  allModules: Module[] = [];
  mod: Module;
  _customer: Customer;
  customerModule: CustomerModules;
  customerId: number;
  applicationId: number;
  applicationSelected: any;
  config: CustomerApplicationConfig;
  active: any;

  @Input() set customer(customer: Customer) {
    this._customer = customer;
  }

  get customer() {
    return this._customer;
  }

  constructor(
    public configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.route.parent.paramMap.subscribe((params: ParamMap) => {
            this.customerId = +params.get('customerId');
          })
          this.applicationId = +params['applicationId'];
          this.applicationSelected = this.configService.getApplication(this.applicationId);

          this.getCustomerApplicationConfig();
          this.getCustomerModules();
        });
  }

  getCustomerApplicationConfig() {
    this.configService.getCustomerApplicationConfig(this.customerId, this.applicationId).subscribe((response) => {
      if (response['status'] == 200) {
        this.config = response['data'][0];
        if (this.config == undefined) {
          this.config = new CustomerApplicationConfig();
          this.config.id = 0;
          let tmp = this.configService.customerApplications.find((customerApplication) => customerApplication.customerId === this.customerId && customerApplication.applicationId === this.applicationId);
          let id;
          if (tmp == undefined) {
            id = 0;
          } else {
            id = tmp.id;
          }
          this.config.idCustomerApplication = id;
          this.config.flagTypeSession = -1;
          this.config.concurrence = 0;
          this.config.uriBackend = '';
          this.config.activationKey = '';
        }
      }
    });
  }

  getCustomerModules() {
    this.initialModules = [];
    this.activeModules = [];
    this.allModules = [];
    this.configService
      .getCustomerApplicationModules(this.customerId, this.applicationId)
      .subscribe((response) => {
        for (let index = 0; index < response['data'].length; index++) {
          this.mod = response['data'][index];
          this.activeModules.push(this.mod);
        }
        this.initialModules = this.activeModules;
        this.configService.getApplicationModulesAPI(this.applicationId).subscribe((response) => {
          this.allModules = response['data'];
          for (let index = 0; index < this.activeModules.length; index++) {
            const mod = this.activeModules[index];
            let idx = this.allModules.findIndex((v) => v.id == mod.id);
            if (idx >= 0) {
              this.allModules[idx].active = true;
            } else {
              this.allModules[idx].active = false;
            }
          }
        });
      });
  }

  onSelectModule(module: Module) {
    if (!module.active) {
      //attivo l'applicazione
      this.configService.insertCustomerApplicationModule(this.customerId, this.applicationId, module.id, module).subscribe(
        (response) => {
          if (response['status'] == 200) {
            //this.router.navigate(['../'], {relativeTo: this.route});
            if (this.activeModules.length == 0) {
              this.configService.insertCustomerApplication(this.customerId, this.applicationId).subscribe(
                (response) => {
                  if (response['status'] == 200) {
                    //console.log('OK Insert application')
                  } else {
                    //console.log("error on selectModule Insert")
                  }
                }
              )
            }
            this.activeModules.push(module);
            //console.log('OK Insert module')
          } else {
            //console.log("error on selectModule Insert")
          }
        }
      )
    } else if (module.active) {
      this.configService.deleteCustomerApplicationModule(this.customerId, this.applicationId, module.id, module).subscribe(
        (response) => {
          if (response['status'] == 200) {
            let x = this.activeModules.findIndex((v) => v.id == module.id);
            this.activeModules.splice(x, 1);
            //console.log('OK Delete Module')
            // se non ci sono più moduli attivati disattivo l'applicazione
            if (this.activeModules.length == 0) {
              this.configService.deleteCustomerApplication(this.customerId, this.applicationId).subscribe(
                (response) => {
                  if (response['status'] == 200) {
                    //console.log('OK Delete application')
                  } else {
                    //console.log("error on selectModule Delete application")
                  }
                }
              )
            }
          } else {
            //console.log("error on selectModule Delete")
          }
        }
      )
    }
  }

  onGenerate(){
    this.configService.getNewCustomerApplicationConfig(this.customerId, this.applicationId, this.config).subscribe((response) => {
      if (response["status"] == 200) {
        this.config.activationKey = response["data"][0].activationKey;
      }
    })
  }

  onSave() {
    if (this.config.id != 0) {
      this.configService.updateCustomerApplicationConfig(this.customerId, this.applicationId, this.config).subscribe((response) => {
        if (response["status"] == 200) {
          this.getCustomerApplicationConfig();
        }
      })
    } else {
      this.configService.insertCustomerApplicationConfig(this.customerId, this.applicationId, this.config).subscribe((response) => {
        if (response["status"] == 200) {
          this.getCustomerApplicationConfig();
        }
      })
    }
  }

  backToCustomers() {
    this.router.navigate(['customers']);
  }
}
