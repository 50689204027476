import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../services/auth.service'
import {ActivatedRoute, Router} from '@angular/router';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class LoginComponent implements OnInit {

  private token: string;

  @Input() username: string;
  @Input() password: string;

  constructor(private authService: AuthService, private route: ActivatedRoute,
    private router: Router) {
      authService.userloggedin.subscribe(() => {
        this.router.navigate(['home'])
      })
    }

  ngOnInit() {
  }

  loginUser() {
    if(!this.username)
      alert('Attenzione - Utente non compilato.')
    else {
      this.authService.postLogin(this.username,this.password).subscribe((payload) =>{
        //alert("Benvenuto "+this.username)
      },({error})=>{
        alert('Attenzione - Login errato')
        //alert(error.message+' - '+JSON.stringify(error[0].msg))
      });
    }
  }
}
