import { catchError } from 'rxjs/operators';
import { Application } from '../../classes/Application';
import { ConfigService } from './../../services/config.service';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-application-new',
  templateUrl: './application-new.component.html',
  styleUrls: ['./application-new.component.css']
})
export class ApplicationNewComponent implements OnInit {

  application: {id: number, name: string, description: string};

  name = "";
  description = "";


  constructor(private configService: ConfigService,
              private route: ActivatedRoute,
              private router: Router) {             
  }

  ngOnInit() {
    this.application = {id: 0, name: "", description: ""};
    this.name = "";
    this.description = "";
  }

  onSaveApplication() {
    this.application.name = this.name;
    this.application.description = this.description;
    this.configService.newApplication(this.application).subscribe(
        (response) => {
          if (response['status'] == 200){
            this.configService.getApplications()
            this.router.navigate(['../'], {relativeTo: this.route})
          }
          else{
            console.log("error on saveApp")
          }
        },
        ( { error }) => {
          console.log(error);
        }
        );
  }
}
