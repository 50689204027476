import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Customer } from '../classes/Customer';
import { Application } from '../classes/Application';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  isCollapsed = true;
  username: string;

  @Output() onNewCustomer = new EventEmitter<Customer>();
  @Output() onNewApplication = new EventEmitter<Application>();

  constructor(private authService: AuthService, private router: Router) {
    this.username = authService.getUsername();
  }

  ngOnInit() {}

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  goToCustomers() {
    this.router.navigate(['customers']);
  }

  newCustomer() {
    this.onNewCustomer.emit();
  }

  goToApplications(){
    this.router.navigate(['applications'])
  }

  newApplication(){
    this.onNewApplication.emit();
  }

}
