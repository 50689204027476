<app-nav></app-nav>
<form #f="ngForm">
  <div class="container">

    <div class="form-group">
      <label for="code">Codice TARGET</label>
      <input
        [(ngModel)]="customer.code"
        class="form-control"
        type="text"
        name="code"
        id="code"
      />
      <!--con le [] leghi in input, con le () leghi in output-->
    </div>
    <div class="form-group">
      <label for="ragSoc">Ragione Sociale</label>
      <input
        [(ngModel)]="customer.ragSoc"
        class="form-control"
        type="text"
        name="ragSoc"
        id="ragSoc"
      />
    </div>
    <div class="form-group" id="flagActive">
      <input
        class="form-check-input text-center align-middle"
        type="checkbox"
        id="flagDisabled"
        name="active"
        [(ngModel)]="customer.active"
      />
      <label
        class="form-check-label text-center align-middle"
        for="flagDisabled"
      >Cliente attivo</label
      >
    </div>
    <div class="form-group form-footer text-center">
      <button
        class="btnBack  btn-primary"
        type="button"
        (click)="saveCustomer()"
      >
        Salva
      </button>
      <button
        class="btnSave  btn-primary"
        type="button"
        (click)="backToCustomers()"
      >
        Annulla
      </button>
    </div>
  </div>
</form>
