import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Customer} from 'src/app/classes/Customer';
import {ConfigService} from 'src/app/services/config.service';
import {Application} from '../classes/Application';
import {CustomerApplication} from '../classes/CustomerApplication';

@Component({
  selector: 'app-customer-applications',
  templateUrl: './customer-applications.component.html',
  styleUrls: ['./customer-applications.component.css']
})
export class CustomerApplicationsComponent implements OnInit {

  customer: Customer;
  customerId: number;
  applications: Application[] = [];
  activeCustomerApplications: CustomerApplication[] = [];
  activeApplications: Application[] = [];

  constructor(public configService: ConfigService,
              private router: Router,
              private route: ActivatedRoute) {
  }


  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.customerId = +params.get('customerId')
      this.customer = this.configService.getCustomerById(this.customerId)
    })

    this.getAllApplications();
  }

  getAllApplications() {
    this.configService.getAllApplicationsAPI().subscribe(
      (response) => {
        this.applications = response['data']
      });
    this.configService.getCustomerApplications(this.customerId).subscribe(
      (response) => {
        for (let index = 0; index < response['data'].length; index++) {
          let customerApplication: CustomerApplication = {
            id: response['data'][index]['id'],
            customerId: this.customerId,
            applicationId: response['data'][index]['id_application'],
            active: true
          }
          this.activeCustomerApplications.push(customerApplication)
          let application = this.applications.find((application) => application.id === customerApplication.applicationId)
          this.activeApplications.push(application);
          let idx = this.applications.findIndex(x => x.id === customerApplication.applicationId)
          this.applications.splice(idx, 1);
        }
        this.configService.applications = this.activeApplications;
        this.configService.customerApplications = this.activeCustomerApplications;
      });

  }
}
