<td class="align-middle">
  <input
    class="checkbox "
    type="checkbox"
    [(ngModel)]="customer.active"
    (click)="enableCustomer($event)"
  />
  <!--
<button class="btnDelete btn-danger btn-sm" type="button" (click)="deleteCustomer()">
  <mat-icon>delete</mat-icon>
</button>-->
</td>
<td id="codCf" class="align-middle">{{ customer.code }}</td>
<td id="ragSoc" class="align-middle">{{ customer.ragSoc }}</td>

<td >
  <div class="row">
    <div class="col-sm-4">
      <button
        class="btnUpdate btn-primary btn-sm"
        type="button"
        (click)="applicationsCustomer()"
      >
        <mat-icon>apps</mat-icon>
      </button>
    </div>
    <div class="col-sm-4">
      <button
        class="btnUpdate btn-warning btn-sm"
        type="button"
        (click)="updateCustomer()"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
</td>
