import { ConfigService } from '../services/config.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../classes/Customer';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
})
export class CustomerDetailComponent implements OnInit {
  // private customerCopy: Customer;
  // private _customer: Customer;

  // @Input() set customer(customer: Customer) {
  //   this._customer = customer;
  //   this.customerCopy = Object.assign({}, customer);
  // }

  // get customer() {
  //   return this._customer;
  // }
  customer: Customer;

  constructor(
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      if (!params.idCustomer) {
        this.customer = new Customer();
      } else {
        this.customer = this.configService.getCustomerById(+params.idCustomer);
      }
    });
  }

  saveCustomer() {
    if (this.customer.id > 0) {
      this.configService.updateCustomer(this.customer).subscribe((data) => {
        //console.log(data);
      });
    } else {
      this.configService.createCustomer(this.customer).subscribe((data) => {
        //console.log(data);
      });
    }
    this.router.navigate(['customers']);
  }

  backToCustomers() {
    this.router.navigate(['customers']);
  }
}
