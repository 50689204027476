import {ModuleInterface} from './../interfaces/module';
import {Injectable, Output} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

//interfaces
import {CustomerInterface} from '../interfaces/customer';
import {ApplicationInterface} from '../interfaces/application'


//classes
import {Customer} from '../classes/Customer';
import {Module} from '../classes/Module';
import {Application} from '../classes/Application';
import {CustomerApplicationConfig} from "@src/app/classes/CustomerApplicationConfig";
import {CustomerApplication} from "@src/app/classes/CustomerApplication";

/*
URLs:

API ncModuliSRV (reserved area): 127.0.0.1:4000
API ncLogistic (client): 127.0.0.1:5000
API ncLogisticSRV (server): 127.0.0.1:4500

*/

@Injectable()
export class ConfigService {
  private APIURL = 'http://licenze.net01cloud.it/config';
  //private APIURL = 'http://127.0.0.1:80/config';
  public customers: Customer[] = [];
  public modules: Module[] = [];
  public applications: Application[] = [];
  public applicationModules: Module[] = [];
  public module: Module;
  customerApplications: CustomerApplication[];

  constructor(private http: HttpClient) {
  }

  getAllModules(): Observable<Module[]> {
    return this.http
      .get<Module[]>(this.APIURL + '/modules/')
      .pipe(catchError(this.errorHandler));
  }

  getAllCustomers(): Observable<Customer[]> {
    return this.http
      .get<Customer[]>(this.APIURL + '/customers/')
      .pipe(catchError(this.errorHandler));
  }

  getAllApplicationsAPI(): Observable<Application[]> {
    return this.http
      .get<Application[]>(this.APIURL + '/applications/')
      .pipe(catchError(this.errorHandler));
  }

  getApplications() {
    this.getAllApplicationsAPI().subscribe(
      (response) => {
        this.applications = response['data']
      }
    )
  }

  //--------------------
  //APPLICATIONS
  //--------------------

  getApplication(id: number) {
    return this.applications.find((application) => application.id === id);
  }

  updateApplication(applicationData: ApplicationInterface) {
    return this.http
      .post<Application>(
        this.APIURL + '/applications/' + applicationData.id + '/edit/', applicationData)
      .pipe(catchError(this.errorHandler));
  }

  newApplication(applicationData: ApplicationInterface) {
    return this.http
      .post<Application>(
        this.APIURL + '/applications/new', applicationData)
      .pipe(catchError(this.errorHandler));
  }

  // lista tutti i moduli dell'applicazione con id = ?
  getApplicationModulesAPI(id: number) {
    return this.http
      .get<Module[]>(
        this.APIURL + '/applications/' + id + '/modules')
      .pipe(catchError(this.errorHandler));
  }

  getApplicationModules(id: number) {
    this.getApplicationModulesAPI(id).subscribe(
      (response) => {
        this.applicationModules = response['data']
      }
    )
  }

  /*
  getcustomer(id): Observable<customer> {
    return this.http
      .get<customer>(this.APIURL + '/customers/' + id)
      .pipe(catchError(this.errorHandler));
  }
*/

  getModulesByCustomer(customerId: number): Observable<Module[]> {
    /*let params = new HttpParams();
    params.set('cod', codCustomer.toString());*/

    return this.http
      .get<Module[]>(this.APIURL + '/customers/' + customerId + '/modules')
      .pipe(catchError(this.errorHandler));
  }

  //get singolo modulo
  getModuleApi(appId: number, moduleId: number) {
    return this.http
      .get<Module[]>(
        this.APIURL + '/applications/' + appId + '/modules/' + moduleId
      )
      .pipe(catchError(this.errorHandler));
  }

  getModule(appId: number, moduleId: number) {
    /*this.getModuleApi(appId, moduleId).subscribe(
      (response) => {
        this.module = response['data'];
      }
    )*/
    return this.applicationModules.find((module) => module.id === moduleId);
  }

  //update module
  updateModule(module: Module) {
    return this.http
      .post<Module>(
        this.APIURL + '/applications/' + module.id_application + '/modules/' + module.id + '/edit/', module)
      .pipe(catchError(this.errorHandler));
  }

  //insert modulo applicazione
  createApplicationModule(module: Module) {
    return this.http
      .post<Module>(
        this.APIURL + '/applications/' + module.id_application + '/modules/new', module)
      .pipe(catchError(this.errorHandler));
  }

  //------------------------------------
  //CUSTOMERS - APPLICATIONS - MODULES
  //------------------------------------

  // config/customers/:customerId/applications/
  getCustomerApplications(customerId: number) {
    return this.http
      .get(this.APIURL + '/customers/' + customerId + '/applications')
      .pipe(catchError(this.errorHandler));
  }

  insertCustomerApplicationConfig(customerId: number, applicationId: number, config: CustomerApplicationConfig) {
    return this.http
      .post(this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/app-config/new', config)
      .pipe(catchError(this.errorHandler));
  }

  updateCustomerApplicationConfig(customerId: number, applicationId: number, config: CustomerApplicationConfig) {
    return this.http
      .put(this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/app-config/edit', config)
      .pipe(catchError(this.errorHandler));
  }

  getNewCustomerApplicationConfig(customerId: number, applicationId: number, config: CustomerApplicationConfig) {
    return this.http
      .get(this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/app-config/activation-key/new')
      .pipe(catchError(this.errorHandler));
  }

  // config/customers/:customerId/applications/:applicationId/activate
  insertCustomerApplication(customerId: number, applicationId: number) {
    return this.http
      .post(this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/activate', {})
      .pipe(catchError(this.errorHandler));
  }

  // config/customers/:customerId/applications/:applicationId/edit
  updateCustomerApplication(customerId: number, applicationId: number) {
    return this.http
      .put(this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/edit', {})
      .pipe(catchError(this.errorHandler));
  }

  // config/customers/:customerId/applications/:applicationId/delete
  deleteCustomerApplication(customerId: number, applicationId: number) {
    return this.http
      .post(this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/delete', {})
      .pipe(catchError(this.errorHandler));
  }

  // config/customers/:customerId/application/:applicationId/config
  getCustomerApplicationConfig(customerId: number, applicationId: number) {
    return this.http
      .get<CustomerApplicationConfig>(this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/app-config')
      .pipe(catchError(this.errorHandler));
  }


  // config/customers/:customerId/applications/:applicationId/modules
  getCustomerApplicationModules(customerId: number, applicationId: number) {
    return this.http
      .get<Module[]>(this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/modules')
      .pipe(catchError(this.errorHandler));
  }

  // config/customers/:customerId/applications/:applicationId/modules/reset
  resetCustomerApplicationModules(customerId: number) {
    return this.http
      .post<Customer>(
        this.APIURL + '/customers/' + customerId + '/applications/modules/reset', {}
      )
      .pipe(catchError(this.errorHandler));
  }

  // config/customers/:customerId/applications/:applicationId/modules/:moduleId/delete
  deleteCustomerApplicationModule(customerId: number, applicationId: number, moduleId: number, module: Module) {
    return this.http
      .post(
        this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/modules/' + moduleId + '/delete', module
      )
      .pipe(catchError(this.errorHandler));
  }

  // config/customers/:customerId/applications/:applicationId/modules/:moduleId/activate
  insertCustomerApplicationModule(customerId: number, applicationId: number, moduleId: number, module: Module) {
    return this.http
      .post(
        this.APIURL + '/customers/' + customerId + '/applications/' + applicationId + '/modules/' + moduleId + '/activate', module
      )
      .pipe(catchError(this.errorHandler));
  }


  /*
  // deprecato
  createCustomerModule(
    customerModule: CustomerModulesInterface,
    customer: Customer
  ) {

    return this.http
      .post<CustomerModules[]>(
        this.APIURL +
        '/customers/' +
        customer.cod +
        '/modules/' +
        customerModule.idModule +
        '/new/',
        {
          customerModule,
        }
      )
      .pipe(catchError(this.errorHandler));
  }
  */

  //GET CUSTOMER

  getCustomer(id: number) {
    if (this.customers.length > 0) {
      return this.customers.find((customer) => customer.id === id);
    } else {
      this.getAllCustomers().subscribe((response) => {
        this.customers = response['data'];
        return this.customers.find((customer) => customer.id === id);
      });
    }
  }

  getCustomerByCod(code: string) {
    if (this.customers.length > 0) {
      return this.customers.find((customer) => customer.code === code);
    } else {
      this.getAllCustomers().subscribe((response) => {
        this.customers = response['data'];
        return this.customers.find((customer) => customer.code === code);
      });
    }
  }

  getCustomerById(id: number) {
    if (this.customers.length > 0) {
      return this.customers.find((customer) => customer.id === id);
    } else {
      this.getAllCustomers().subscribe((response) => {
        this.customers = response['data'];
        return this.customers.find((customer) => customer.id === id);
      });
    }
  }

  //CRUD CUSTOMER

  createCustomer(customer: CustomerInterface) {
    //customer.id = this.customer.length + 1;
    //this.customers.splice(0, 0, customer);

    return this.http
      .post<Customer[]>(this.APIURL + '/customers/new/', customer)
      .pipe(catchError(this.errorHandler));
  }

  updateCustomer(customer: CustomerInterface) {
    const idx = this.customers.findIndex((v) => v.id == customer.id);
    console.log(customer)
    if (idx != -1) {
      this.customers[idx] = customer;
      return this.http
        .post<Customer[]>(
          this.APIURL + '/customers/' + customer.id + '/edit/',
          customer
        )
        .pipe(catchError(this.errorHandler));
    }
  }

  setActiveCustomer(customer: CustomerInterface) {

    const index = this.customers.indexOf(customer);
    if (index >= 0) {
      return this.http
        .post<Customer[]>(this.APIURL + '/customers/', customer)
        .pipe(catchError(this.errorHandler));
    }
  }

  errorHandler(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get customer-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage)
    return throwError(errorMessage);
  }
}
