<!--<app-nav></app-nav>

<div class="container">
  <h2 class="py-4">Clienti</h2>

  <div class="form-group mb-4">
    <input class="form-control" type="text" [(ngModel)]="searchText" placeholder="Search">
  </div>

  <table class="table" *ngIf="(customers | filter: searchText).length > 0; else noResults">
    <colgroup>
      <col width="5%">
      <col width="20%">
      <col width="70%">
      <col width="5%">
    </colgroup>
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Codice</th>
        <th scope="col">Ragione Sociale</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr app-user *ngFor="let customer of customers | filter: searchText; let i = index"
      [customer-data]="customer"
      (onDeletecustomer)="onDeletecustomer($event)"
      (onSelectcustomer)="onSelectcustomer($event)">
        <th scope="row">{{i + 1}}</th>
        <td>{{customer.codCf}}</td>
        <td>{{customer.ragSocCf}}</td>
        <td><button mat-raised-button (click)="goTocustomerModules(customer) ">Moduli</button></td>

      </tr>
    </tbody>
  </table>

  <ng-template #noResults>
    <p>No results found for "{{searchText}}".</p>
  </ng-template>
</div>
-->

<app-nav></app-nav>
<div class="container">
  <div class="form-inline align-items-center">
    <input
      class="form-check-input text-center align-middle"
      type="checkbox"
      id="flagDisabled"
      [(ngModel)]="flagInattivi"
      (click)="getCustomers()"
    />
    <label class="form-check-label text-center align-middle" for="flagDisabled"
      >Solo Clienti Attivi</label
    >
    <input
      class="form-control"
      type="text"
      [(ngModel)]="searchText"
      placeholder="Search"
    />
    <button
    class="btn btn-primary"
    (click)="onNewCustomer()">Nuovo Cliente
    </button>
  </div>

  <div class="row">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Attivo</th>
          <th>Codice</th>
          <th>Ragione Sociale</th>
          <th class="action">Opzioni</th>
        </tr>
      </thead>

      <tbody>
        <tr
          app-user
          *ngFor="
            let customer of customers
              | filter: searchText
              | paginate: { itemsPerPage: 10, currentPage: p };
            let i = index
          "
          [customer-data]="customer"
          (onDeleteUser)="onSetActiveCustomer($event)"
          (onSelectUser)="onSelectUser($event)"
        ></tr>
      </tbody>
    </table>
    <ng-template #noResults>
      <p>No results found for "{{ searchText }}".</p>
    </ng-template>

    <div class="mx-auto">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
</div>
