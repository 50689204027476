<app-nav></app-nav>

<div class="row">
  <div class="col-2">
    <div class="list-group">
      <a
        *ngFor="let activeApplication of activeApplications"
        [routerLink]="[activeApplication.id, 'modules']"
        routerLinkActive="active"
        [ngStyle]="{'font-weight': 'bold'}"
        href="#" class="list-group-item">
        {{ activeApplication.name }}
      </a>
      <a
        *ngFor="let application of applications"
        [routerLink]="[application.id, 'modules']"
        routerLinkActive="active"
        href="#" class="list-group-item">
        {{ application.name }}
      </a>
    </div>
  </div>
  <div class="col-10">
    <router-outlet></router-outlet>
  </div>
</div>
