<app-nav></app-nav>
<br />
<body>
<div class="container">
  <div class="row justify-content-center align-items-center">
    <img
      class="logo"
      src="https://www.nc-solutions.it/wp-content/uploads/logo-nc-solutions-white.png"
    />
    <!--<button type="button" class="btn btn-secondary rounded-pill"><fa-icon [icon]="customer.iconFA"></fa-icon>Moduli</button>

      <button type="button" class="btn btn-secondary rounded-pill"><fa-icon [icon]="module.iconFA"></fa-icon>  {{module.name}}</button>

        <button type="button" class="btn btn-secondary rounded-pill"><fa-icon [icon]="iconStock"></fa-icon>  Giacenze</button>
        <button type="button" class="btn btn-secondary rounded-pill"><fa-icon [icon]="iconInventory"></fa-icon>  Inventario</button>
        <button type="button" class="btn btn-secondary rounded-pill"><fa-icon [icon]="iconProduction"></fa-icon>  Produzione</button>
      -->
  </div>
</div>

</body>
