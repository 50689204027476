//modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

//service
import { RouteGuardService } from './route-guard.service';

//components
import { CustomersComponent } from './customers/customers.component';
import { CustomerModulesComponent } from './customer-modules/customer-modules/customer-modules.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ApplicationsComponent} from './applications/applications.component';
import { ApplicationNewComponent} from './applications/application-new/application-new.component';
import { CustomerApplicationsComponent } from './customer-applications/customer-applications.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: '#',
    component: HomeComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: 'customers/:idCustomer/edit',
    component: CustomerDetailComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: 'customers/new',
    component: CustomerDetailComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: 'customers/:idCustomer/modules',
    component: CustomerModulesComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: 'customers/:idCustumer/modules/:id/new',
    component: CustomerModulesComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: 'customers/:idCustomer/modules/reset',
    component: CustomerModulesComponent,
    canActivate: [RouteGuardService],
  },

  {
    path: 'customers/:customerId/applications',
    component: CustomerApplicationsComponent,
    children:[
      {path: ':applicationId/modules', pathMatch: 'full', component: CustomerModulesComponent}
    ]
  },


  {
    path: 'applications',
    canActivate: [RouteGuardService],
    component: ApplicationsComponent,
    children: [
      { path: 'new', component: ApplicationNewComponent },
      { path: ':id/edit', loadChildren: () => import('./applications/application-edit/application-edit.module').then(m => m.ApplicationEditModule)},
    ] 
  }

];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [RouteGuardService],
})
export class RoutingModule {}
