import { Observable } from 'rxjs';
import { LoginComponent } from './login/login.component';
import { AuthService } from './services/auth.service';

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if(this.authService.isAuthenticated()){
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
