import { Component } from '@angular/core';
import { Customer } from './classes/Customer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ncModulescustomers';
  showForm = false;
  customerSelected: Customer = new Customer();


  newCustomer() {
    this.showForm = true;
    this.customerSelected = new Customer();
  }
}
