import {CustomerApplicationConfigInterface} from "../interfaces/customer-application-config";

export class CustomerApplicationConfig implements CustomerApplicationConfigInterface {
  id: number = 0;
  idCustomerApplication: number = 0;
  flagTypeSession: number = 0;
  concurrence: number = 0;
  uriBackend: string = '';
  activationKey: string = '';

  public constructor(init?: Partial<CustomerApplicationConfigInterface>) {
    Object.assign(this, init);
  }
}
